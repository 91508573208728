import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FilterGroup, FilterOption, reduceFilterGroupToSelectedOptions } from '@app/components/filter/filter.component';
import { AuthService } from '@app/services';
import { breakPoints } from '@app/styles/theme';
import { CalendarEvent } from '@models/common/calendar-event.model';
import { addWeeks, endOfMonth, format, getDay, startOfMonth, subWeeks } from 'date-fns';

@Component({
    templateUrl: './calendar.template.html',
    styleUrls: ['./calendar.style.scss'],
})
export class CalendarView implements OnInit {
    eventTypes: (typeof CalendarEvent)[] = [];
    usesFilters = false;
    date: Date = startOfMonth(new Date());
    events: CalendarEvent[] = [];
    scopedEvents: CalendarEvent[] = [];
    searchString: string;
    maxEventsPerDay = { mobile: 4, desktop: 3 };
    selectedDay;
    filterGroups: Array<FilterGroup> = [];
    isLoading = false;

    constructor(
        protected auth: AuthService,
        protected router: Router,
        private breakpointObserver: BreakpointObserver
    ) {}

    ngOnInit(): void {
        this.prepareFilters();
    }

    getEventDescription(event): { startAt: string; endAt: string } {
        return {
            startAt: this.formattedEventDate(event.startAt),
            endAt: this.formattedEventDate(event.endAt),
        };
    }

    onClickViewMore(day: any): void {
        if (this.selectedDay === day) {
            this.selectedDay = null;
        }
        this.selectedDay = day;
    }

    formattedEventDate(date: Date): string {
        return format(date, 'MMM DD');
    }

    getPlacement(date: string): 'left' | 'right' {
        const day = getDay(date);
        const isThursdayFridayOrSaturday = day > 3;

        return isThursdayFridayOrSaturday ? 'left' : 'right';
    }

    onCloseAdditionalEvents(): void {
        this.selectedDay = null;
    }

    onHoverEvent(): void {
        this.selectedDay = null;
    }

    onFilter(): void {
        this.moldFilters();
        this.loadEvents();
    }

    onSearch(search: string): void {
        this.searchString = search;
        this.scopeEvents();
    }

    onChangeDate(date: any): void {
        this.date = startOfMonth(date);
        this.loadEvents();
    }

    onDayClicked(_event: CalendarEvent): void {}

    onClickEvent(_event: CalendarEvent): void {}

    protected prepareFilters(): void {}

    protected moldFilters(): void {}

    protected loadEvents(): void {
        Promise.all(
            this.eventTypes.map((eventType) =>
                eventType
                    .param('company', this.auth.company.id)
                    .param('employee', this.auth.employee.id)
                    .where('startAt', subWeeks(this.date, 1))
                    .where('endAt', addWeeks(endOfMonth(this.date), 1))
                    .when(this.usesFilters, (query) => {
                        for (const filter of this.filterGroups) {
                            const selectedFilters = reduceFilterGroupToSelectedOptions(filter).map(
                                (option: FilterOption) => option.value
                            );

                            if (selectedFilters.length) {
                                query.where(filter, selectedFilters);
                            }
                        }
                        return query;
                    })
                    .all()
            )
        ).then((response) => {
            this.events = response.reduce((accumulator, [responseEvents]) => accumulator.concat(responseEvents), []);
            this.scopeEvents();
        });
    }

    protected scopeEvents(): void {
        if (!this.searchString) {
            this.scopedEvents = this.events;
            return;
        }

        this.scopedEvents = this.events.filter((event) => {
            return (
                event.monthCalendarTitle.toLowerCase().indexOf(this.searchString) > -1 ||
                event.monthCalendarTooltipDescription.toLowerCase().indexOf(this.searchString) > -1
            );
        });
    }

    get isMobile(): boolean {
        return !this.breakpointObserver.isMatched(`(min-width: ${breakPoints.md}px)`);
    }
}
