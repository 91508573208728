import { AdditionalPayItem } from '@app/models/payroll/additional-pay-item.model';
import { CompanyAdditionalIncome } from '@app/models/payroll/company-additional-income.model';
import { DeductionItem } from '@app/models/payroll/deduction-item.model';
import { EmployeeBenefitBenefitItem } from '@app/models/payroll/employee-benefit-benefit-item.model';
import { EmployeeBenefitDeductionItem } from '@app/models/payroll/employee-benefit-deduction-item.model';
import { EmployeeDeductionItem } from '@app/models/payroll/employee-deduction-item.model';
import { VacationPayItem } from '@app/models/payroll/vacation-pay-item.model';
import { Ytd } from '@app/models/payroll/ytd.model';
import { HandsOnTableView } from '@app/modules/common/views';
import { YTDHeader } from '@app/modules/payroll/interfaces/ytd-header.interface';
import { YtdIndexView } from '@app/modules/payroll/views/record/ytd/index/ytd-index.view';
import Handsontable from 'handsontable/base';
import { RowObject } from 'handsontable/common';
import { ColumnSettings } from 'handsontable/settings';
import { NumericEditor } from './handsontable-helpers';

export interface Datum {
    [key: string]: number;
}

type BenefitDeductionItemTag = 'EmployeeBenefitDeductionItem' | 'EmployeeBenefitBenefitItem' | 'EmployeeDeductionItem';
type BenefitDeductionNameWithTag = `${string} ${BenefitDeductionItemTag}`;

const benefitDeductionNameWithTag = (tag: BenefitDeductionItemTag, name: string): string => `${name} ${tag}`;

const columnNameHasTag = (
    tag: BenefitDeductionItemTag,
    columnName: string
): columnName is BenefitDeductionNameWithTag => columnName.includes(tag);

const columnNameWithoutTag = (tag: BenefitDeductionItemTag, columnName: BenefitDeductionNameWithTag): string =>
    columnName.substring(0, columnName.indexOf(tag) - 1);

export function getItemFromYtd(
    columnName: string,
    ytd: Ytd,
    companyAdditionalIncomes: CompanyAdditionalIncome[]
):
    | AdditionalPayItem
    | DeductionItem
    | EmployeeBenefitBenefitItem
    | EmployeeBenefitDeductionItem
    | EmployeeDeductionItem
    | VacationPayItem {
    if (ytd[columnName]) {
        return ytd[columnName];
    }

    if (columnName === 'regular_pay') {
        return ytd.regularPay;
    }

    if (columnName === 'vacation pay') {
        return ytd.vacationPay;
    }

    const additionalIncomeType: CompanyAdditionalIncome | undefined = companyAdditionalIncomes.find(
        (item: CompanyAdditionalIncome) => item.name === columnName
    );

    if (additionalIncomeType) {
        return ytd.getAdditionalPayItem(additionalIncomeType.name, additionalIncomeType.displayName);
    }

    if (columnNameHasTag('EmployeeBenefitDeductionItem', columnName)) {
        const deductionItemName = columnNameWithoutTag('EmployeeBenefitDeductionItem', columnName);
        const item = ytd.benefitDeductionItems.find(({ name }) => name === deductionItemName);
        item.param('employeeBenefit', item.deductableId);
        return item;
    }

    if (columnNameHasTag('EmployeeBenefitBenefitItem', columnName)) {
        const benefitItemName = columnNameWithoutTag('EmployeeBenefitBenefitItem', columnName);
        const item = ytd.benefitBenefitItems.find(({ name }) => name === benefitItemName);
        item.param('employeeBenefit', item.employeeBenefitId);
        return item;
    }

    if (columnNameHasTag('EmployeeDeductionItem', columnName)) {
        const deductionItemName = columnNameWithoutTag('EmployeeDeductionItem', columnName);
        const item = ytd.employeeDeductions.find(({ name }) => name === deductionItemName);
        item.param('employeeDeduction', item.deductableId);
        return item;
    }
}
export function buildColumns(headers: YTDHeader[]): ColumnSettings[] {
    return headers.map((item: YTDHeader) => ({
        data: item.data,
        title: item.title,
        type: item.type,
        readOnly: item.readOnly,
        ...(item.type === 'numeric' && { editor: NumericEditor }),
    }));
}
export function buildDataset(this: YtdIndexView): Datum[] {
    const DEFAULT_AMOUNT = 0;

    return this.ytds.map((ytd: Ytd) =>
        this.columns.reduce((acc: RowObject, column: ColumnSettings) => {
            // for each column inside each YTD (one YTD per employee)

            const columnName = column.data as string;

            // regular pay is, oddly enough, an "additional income type"
            // but also, not an additional income type that a company can have
            // it only appears on YTDs and needs to be handled differently
            if (columnName === 'regular_pay') {
                acc[columnName] = ytd.regularPay.amount;
                return acc;
            }

            if (columnName === 'vacation pay') {
                acc[columnName] = ytd.vacationPay.amount;
                return acc;
            }

            if (columnName === 'jurisdiction') {
                acc[columnName] = ytd.jurisdiction;
                return acc;
            }

            if (ytd[columnName]) {
                acc[columnName] = ytd[columnName].amount;
                return acc;
            }

            // additional income types
            const additionalIncomeType: CompanyAdditionalIncome | undefined = this.companyAdditionalIncomes.find(
                (item: CompanyAdditionalIncome) => item.name === columnName
            );

            if (additionalIncomeType) {
                acc[columnName] = ytd.getAdditionalPayItem(columnName, additionalIncomeType.displayName).amount;
                return acc;
            }

            // benefit / deduction columns (not all employees will have these)

            // ytd, columnName, companyBenefits
            if (columnNameHasTag('EmployeeBenefitDeductionItem', columnName)) {
                const deductionItemName = columnNameWithoutTag('EmployeeBenefitDeductionItem', columnName);
                const existingDeductionItem = ytd.benefitDeductionItems.find(({ name }) => name === deductionItemName);

                // if they already have a YTD for the deduction
                if (existingDeductionItem) {
                    acc[columnName] = existingDeductionItem.amount;
                    return acc;
                }

                // figure out if they are on the benefit
                const companyBenefit = this.companyBenefits.find(({ name }) => name === deductionItemName);
                const employeeBenefit = companyBenefit.employeeBenefits.find(({ employeeId }) => employeeId === ytd.id);

                if (!employeeBenefit) {
                    return acc;
                }

                const newDeductionItem = new EmployeeBenefitDeductionItem({
                    name: deductionItemName,
                    amount: DEFAULT_AMOUNT,
                    paystubId: ytd.paystubId,
                    deductableId: employeeBenefit.id,
                });

                ytd.benefitDeductionItems = [...ytd.benefitDeductionItems, newDeductionItem];

                acc[columnName] = DEFAULT_AMOUNT;
                return acc;
            }

            if (columnNameHasTag('EmployeeBenefitBenefitItem', columnName)) {
                const benefitItemName = columnNameWithoutTag('EmployeeBenefitBenefitItem', columnName);
                const existingBenefitItem = ytd.benefitBenefitItems.find(({ name }) => name === benefitItemName);

                // if they already have a YTD for the benefit
                if (existingBenefitItem) {
                    acc[columnName] = existingBenefitItem.amount;
                    return acc;
                }

                // figure out if they are on the benefit
                const companyBenefit = this.companyBenefits.find(({ name }) => name === benefitItemName);
                const employeeBenefit = companyBenefit.employeeBenefits.find(({ employeeId }) => employeeId === ytd.id);

                if (!employeeBenefit) {
                    return acc;
                }

                const newBenefitItem = new EmployeeBenefitBenefitItem({
                    name: benefitItemName,
                    amount: DEFAULT_AMOUNT,
                    paystubId: ytd.paystubId,
                    employeeBenefitId: employeeBenefit.id,
                });

                ytd.benefitBenefitItems = [...ytd.benefitBenefitItems, newBenefitItem];

                acc[columnName] = DEFAULT_AMOUNT;
                return acc;
            }

            if (columnNameHasTag('EmployeeDeductionItem', columnName)) {
                const deductionItemName = columnNameWithoutTag('EmployeeDeductionItem', columnName);
                const existingDeductionItem = ytd.employeeDeductions.find(({ name }) => name === deductionItemName);

                // if they already have a YTD for the deduction
                if (existingDeductionItem) {
                    acc[columnName] = existingDeductionItem.amount;
                    return acc;
                }

                // figure out if they are on the deduction
                const companyDeduction = this.companyDeductions.find(({ name }) => name === deductionItemName);
                const employeeDeduction = companyDeduction.employeeDeductions.find(
                    ({ employeeId }) => employeeId === ytd.id
                );

                if (!employeeDeduction) {
                    return acc;
                }

                const newDeductionItem = new EmployeeDeductionItem({
                    name: deductionItemName,
                    amount: DEFAULT_AMOUNT,
                    paystubId: ytd.paystubId,
                    deductableId: employeeDeduction.id,
                });

                ytd.employeeDeductions = [...ytd.employeeDeductions, newDeductionItem];

                acc[columnName] = DEFAULT_AMOUNT;
                return acc;
            }

            return acc;
        }, {})
    );
}

export function renderCells(
    this: HandsOnTableView,
    row: number,
    col: number
): { readOnly: boolean; renderer: CallableFunction } | void {
    const dataInCell = this.getHotInstance().getData()[row][col];
    if (dataInCell !== null) {
        return;
    }

    return {
        readOnly: true,
        renderer: (_instance: Handsontable, td: HTMLTableDataCellElement) => {
            td.style.background = 'var(--grey-500)';
            td.title = this.languageService.translate('payroll.ytds.employeeNotAssignedToBenefit');
        },
    };
}

export function getRowHeaderWidth(rowHeaders: string[]): number {
    if (rowHeaders.length === 0) {
        return 0;
    }

    const pixelsPerLetter = 9;
    const lengthOfLongestName = Math.max(...rowHeaders.map((header) => header.length));

    return lengthOfLongestName * pixelsPerLetter;
}
